import { template as template_0211c88ef9b04df1a937d0e53634ccbe } from "@ember/template-compiler";
const WelcomeHeader = template_0211c88ef9b04df1a937d0e53634ccbe(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
